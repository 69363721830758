import apiProvider from "@/utils/api";
import {AxiosPromise} from "axios";
import {toUrl} from "@/utils/helpers";

const rootPath = "operationGroupOperations";

class OperationGroupOperationService {

    public deleteBopOperation(id: string, isCalorifugeageTyuauterie: boolean = false): AxiosPromise {
        return apiProvider.delete(`${apiProvider.getUrl()}${rootPath}/${id}${toUrl({isCalorifugeageTyuauterie})}`)
    }
}

export default new OperationGroupOperationService();
